// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/kr_ikon.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#bit h1, #bit h2, #bit h3, #bit p, #bit .ui.header {
  color: #ccc; }

#bit h1 {
  padding-left: 35px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: 2px 6px;
  background-size: 25px auto; }

#bit .projects.dropdown .menu .item {
  padding-top: 10px; }

#bit .projects.dropdown .ui.header {
  margin: 0 !important; }

#bit .controls p.subtitle {
  position: relative;
  top: -8px;
  padding-left: 35px; }

.ui.modal {
  text-align: center !important; }
`, "",{"version":3,"sources":["webpack://./src/components/App/App.scss"],"names":[],"mappings":"AAAA;EAEI,WAAW,EAAA;;AAFf;EAKI,kBAAkB;EAClB,yDAAsD;EACtD,4BAA4B;EAC5B,0BAA0B,EAAA;;AAR9B;EAYM,iBAAiB,EAAA;;AAZvB;EAeM,oBAAoB,EAAA;;AAf1B;EAoBM,kBAAkB;EAClB,SAAS;EACT,kBAAkB,EAAA;;AAKxB;EACE,6BAA6B,EAAA","sourcesContent":["#bit {\n  h1,h2,h3,p,.ui.header {\n    color: #ccc;\n  }\n  h1 {\n    padding-left: 35px;\n    background-image: url( '../../../assets/kr_ikon.svg' );\n    background-position: 2px 6px;\n    background-size: 25px auto;\n  }\n  .projects.dropdown {\n    .menu .item {\n      padding-top: 10px;\n    }\n    .ui.header {\n      margin: 0 !important;\n    }\n  }\n  .controls {\n    p.subtitle {\n      position: relative;\n      top: -8px;\n      padding-left: 35px;    \n    }\n\n  }\n}\n.ui.modal {\n  text-align: center !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
