import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'semantic-ui-react'
import './Dialog.scss'

const Dialog = ( props ) => {

  const jsxDialog = ( dlgOpts ) => {
    return (
      <Modal
        centered={false}
        header={dlgOpts.header}
        content={dlgOpts.message}
        actions={dlgOpts.actions}
        open={true}
      />
    )
  }

  return props.dlgOpts ? jsxDialog( props.dlgOpts ) : <></>
}

Dialog.propTypes = {
  dlgOpts: PropTypes.object
}

export default Dialog
