import {default as Debug} from './modules/Debug.js';

/**
 * DrPublish PluginAPI Beta-2021
 */

class Article {
    /**
     * Get the currently selected publication
     * @throws Rejects promise if response is not an object
     * @returns {Array} Publication metadata
     */
    static async getPublication() {
        return sendMessage(createMessage('get-publication'), data => {return !(typeof data === 'object' && data) && pluginName + ': get-publication callback not object'});
    }
 
    /**
     * @deprecated Use giveFocus()
     */
    static async focusPlugin() {}

    /**
     * @deprecated ?
     * @param {string} name 
     * @param {object} options
     * @returns {boolean}
     * @throws Rejects promise if response not TRUE
     */
    static async startPlugin(app, options) {
        return sendMessage(createMessage('app-start', {app, options}), data => {return data !== true && pluginName + ': app-start callback not TRUE'});
    }

    /**
     * @deprecated ?
     * @param {string} name 
     * @returns {boolean}
     * @throws Rejects promise if response not TRUE
     */
    static async stopPlugin(app) {
        return sendMessage(createMessage('app-stop', {app}), data => {return data !== true && pluginName + ': app-stop callback not TRUE'});
    }

    /**
     *  @returns {string} string matching ^\d+$
     *  @throws Rejects promise if response not ^\d+$
     */
    static async getId() {
        return sendMessage(createMessage('article-id-get'), data => {return !(typeof data === 'string' && data.match(/^\d+$/)) && pluginName + ': article-id-get callback not ^\d+$'});
    }

    /**
     *  @returns {string} string matching ^\d+$
     *  @throws Rejects promise if response not ^\d+$
     */
    static async getPackageId() {
        return sendMessage(createMessage('package-id-get'), data => {return !(typeof data === 'string' && data.match(/^\d+$/)) && pluginName + ': package-id-get callback not ^\d+$'});
    }

    /**
     *  @returns {string}
     *  @throws Rejects promise if response not a string with length > 1
     */
    static async getPacakgeGuid() {
        return sendMessage(createMessage('package-guid-get'), data => {return !(typeof data === 'string' && data.length) && pluginName + ': package-guid-get callback not a string with length > 1'});
    }

    /**
     * Not sure if this works or what it does
     * 
     * @deprecated ?
     */
    static async clearMetaInfo() {
        return sendMessage(createMessage('article-metainfo-clear'), data => {return data !== true && pluginName + ': article-metainfo-clear callback not TRUE'});
    }

    /**
     * @returns {Array}
     * @throws Rejects promise if response not an array
     */
    static async getTags() {
        return sendMessage(createMessage('article-tags-get'), data => {return !Array.isArray(data) && pluginName + ': article-tags-get callback not array'});
    }

    /**
     * @todo Find out how the save parameter works
     * @todo Document tags parameter format
     * @param {Array} tags
     * @param {Boolean} save
     * @returns {Boolean}
     * @throws Rejects promise if response not TRUE
     */
    static async setTags(tags, save) {}

    static async getCustomMeta(name) {
        return sendMessage(createMessage('article-custom-meta-get', {name}), data => {return !(typeof data === 'object' && data) && pluginName + ': article-custom-meta-get callback not object'});
    }

    static async setCustomMeta(name, value) {
        return sendMessage(createMessage('article-custom-meta-set', {name, value}), data => {return !(typeof data === 'object' && data) && pluginName + ': article-custom-meta-set callback not object'});
    }

    static async setMetaChanged() {}

    static async addTag(tag) {}

    static async addTags(tags) {}

    static async removeTag(tag) {}

    static async getSelectedCategories() {
        return sendMessage(createMessage('article-categories-selected-get'), data => {return !Array.isArray(data) && pluginName + ': article-categories-selected-get callback not array, perhaps none configured?'});
    }

    static async saveCategories() {}

    static async setCategories(categories) {}

    static async addCategories(categories) {}

    static async removeCategories(categories) {}

    static async setMainCategory(category) {}

    static async getSource() {}

    static async setSource(source) {}

    static async getStatus() {}

    static async setStatus(status) {}

    static async getPublishedDatetime() {}

    static async setPublishedDatetime(published) {}

    static async getAuthors() {}

    static async setAuthors(authors) {}

    static async addAuthors(authors) {}

    static async removeAuthors(authors) {}

    static async setKeywords(keywords) {}

    static async getKeywords() {}

    static async getCurrentContent() {
        return sendMessage(createMessage('article-content-get'), data => {return !(typeof data === 'object' && data && data instanceof Object) && pluginName + ': article-content-get callback not object'});
    }

    static async setCurrentContent(content) {
        return sendMessage(createMessage('article-content-set', {content}), data => {return !(typeof data === 'object' && data) && pluginName + ': article-custom-meta-set callback not object'});
    }

    static async getArticletypeId() {}

    static async setArticletypeId(id) {}

    /**
     * Maximize the plugin iframe body
     * @throws Rejects promise if response not TRUE
     * @param {string} windowTitle The fullscreen modal title
     * @returns {boolean} Success status
     * @todo Title not used?
     */
    static async maximizePluginWindow(windowTitle) {
        return sendMessage(createMessage('editor-pane-maximize'), data => {return data !== true && pluginName + ': editor-pane-maximize callback not TRUE'});
    }

    /**
     * Restore the plugin iframe body to appbar size
     * @throws Rejects promise if response not TRUE
     * @returns {boolean} Success status
     */
    static async restorePluginWindow() {
        return sendMessage(createMessage('restore-app-window'), data => {return data !== true && pluginName + ': restore-app-window callback not TRUE'});
    }

    static async getByline() {
        return sendMessage(createMessage('article-byline-get'), data => {return typeof data !== 'string' && pluginName + ': article-byline-get invalid byline received'});
    }

    static async setByline(byline, save) {
        return sendMessage(createMessage('article-byline-set', {byline}), data => {return data !== true && pluginName + ': article-byline-set callback not TRUE'});
    }

    static async setGeolocations(geolocations) {}

    static async getGeolocations() {}

    static async getProperties() {}

    static async setProperties(name, value) {}

    static async getMeta(name = null) {
        return sendMessage(createMessage('article-meta-get', {name}), data => {return data === null && pluginName + ': article-meta-get callback is NULL'});
    }

    static async setMeta(name, value) {}

    static async save() {
        return sendMessage(createMessage('article-save'), data => {return data !== true && pluginName + ': article-save callback not TRUE'});
    }
}

class Editor {
    static async getActiveEditor() {
        return sendMessage(createMessage('get-active-editor'), data => {return typeof data !== 'string' && pluginName + ': get-active-editor callback not string'});
    }

    /**
     * Add a right click menu to plugin elements
     * @throws Rejects promise if response not TRUE
     * @param {string} label 
     * @param {function} callback
     * @returns TRUE
     */
    static async registerMenuAction(label, callback, icon, trigger) {
        // Backwards compatibility
        if (typeof label === 'object' && label) {
            ({label, callback, icon, trigger} = label);
        }
        
        const message = createMessage('register-menu-action', {label, icon, trigger}, true);

        // message.callback is a one-time calback responding to the register-menu-action message
        // message.data.callback.eventKey callback is used every time the menu action is selected 
        message.data.callback.eventKey = message.callback.replace(/\d+$/, label);
        addListener(message.data.callback.eventKey, callback);

        return sendMessage(message, data => {return data !== true && pluginName + ': register-menu-action callback not TRUE'});
    }

    static async registerMenuActionGroup() {}

    static async registerHoverAction(action) {}

    static async getSelectedPluginElement() {}

    static async directionalCastle(elementId, direction = null) {
        if (direction === true) {
            direction = 'backward';
        }

        return sendMessage(createMessage('editor-directional-castle', {elementId, direction}), data => {return data !== true && pluginName + ': editor-directional-castle callback not TRUE'});
    }

    static getEditorType() {
        return sendMessage(createMessage('editor-get-type', {}), data => {return data === undefined && pluginName + ': editor-get-type callback undefined'});
    }

    static async replaceElementById(id, element) {
        return sendMessage(createMessage('editor-element-replace-byid', {id, element}), data => {return data !== true && pluginName + ': editor-element-replace-byid callback not TRUE'});
    }

    static async replacePluginElementById(id, element) {}

    /**
     * Set the innerHTML of an article element
     * @throws Rejects promise if response not TRUE
     * @param {string} id 
     * @param {string} element HTML string
     * @returns {boolean} Success status
     */
    static async setElementContentById(id, element) {
        return sendMessage(createMessage('editor-element-set-byid', {id, element}), data => {return data !== true && pluginName + ': editor-element-set-byid callback not TRUE'});
    }

    static async deleteElementById(id) {
        return sendMessage(createMessage('editor-element-delete-byid', {id}), data => {return data !== true && pluginName + ': editor-element-delete-byid callback not TRUE, perhaps ID doesnt exist?'});
    }

    static async getHTMLById(id) {
        return sendMessage(createMessage('editor-element-get-byid', {id}), data => {return typeof data !== 'string' && pluginName + ': editor-element-get-byid callback not string, perhaps ID doesnt exist?'});
    }

    static async getHTMLBySelector(selector) {
        return sendMessage(createMessage('editor-elements-get-byselector', {selector}), data => {return !Array.isArray(data) && pluginName + ': editor-elements-get-byselector callback not array, perhaps selector doesnt match anything?'});
    }

    static async getCurrentEditorHTMLBySelector(selector) {
        return sendMessage(createMessage('editor-current-elements-get-byselector', {selector}), data => {return !Array.isArray(data) && pluginName + ': editor-current-elements-get-byselector callback not array, perhaps selector doesnt match anything?'});
    }

    static async getCategories() {
        return sendMessage(createMessage('get-categories'), data => {return !Array.isArray(data) && pluginName + ': get-categories callback not array, perhaps none configured?'});
    }

    static async getParentCategories(category) {}

    static async getParentIds(id, selector) {}

    static async getTagTypes() {}

    static async getTagType(id) {}

    static async clear() {}

    static async insertString(string, noModifications) {
        return sendMessage(createMessage('editor-insert-string', {string, noModifications: !!noModifications}), data => {return data !== true && pluginName + ': editor-insert-string callback not true, perhaps no article field selected'});
    }

    static async insertElement(element, options) {}

    static async removeClasses(id, classes) {
        return sendMessage(createMessage('editor-classes-remove', {id, classes}), data => {return data !== true && pluginName + ': editor-classes-remove callback not true'});
    }

    static async addClasses(id, classes) {
        return sendMessage(createMessage('editor-classes-add', {id, classes}), data => {return data !== true && pluginName + ': editor-classes-add callback not true'});
    }

    static async markAsActive(id) {}

    static async setAttributeById(id, attribute, value) {
        return sendMessage(createMessage('editor-element-attribute-set-byid', {id, attribute, value}), data => {return data !== true && pluginName + ': editor-element-attribute-set-byid callback not true'});
    }

    static async setStyleById(id, attribute, value) {
        return sendMessage(createMessage('editor-element-style-set-byid', {id, attribute, value}), data => {return data !== true && pluginName + ': editor-element-style-set-byid callback not true'});
    }

    static async initMenu(menus) {}

    static async openPluginElementEditor(id) {}

    static async getTotalWordCount() {}

    static async updateAssetOption(articleId, key, value) {}

    static async updateAssetData(data) {}

    static async getAssetData(articleId) {}

    static async insertNestedAsset(parentElementId, markup, data) {}

    static async insertEmbeddedAsset(markup, data) {}

    /**
     * Update the options object of an embedded asset
     * @throws Rejects promise if response not TRUE
     * @param {string} id 
     * @param {Object} options
     * @returns {boolean} Success status
     */
    static async updateEmbeddedAsset(internalId, options) {
        return sendMessage(
            {...createMessage('update-embedded-asset', {embeddedTypeId: 6, assetType: 'script', internalId, options}, true), callback: null},
            data => {return data !== true && pluginName + ': update-embedded-asset callback not TRUE'}
        );
    }

    static async setElementParent(element, parent) {
        return sendMessage(createMessage('editor-set-element-parent', {element, parent}), data => {return data !== true && pluginName + ': editor-set-element-parent callback not TRUE'});
    }

    static async setElementAfter(element, sibling) {
        return sendMessage(createMessage('editor-set-element-after', {element, sibling}), data => {return data !== true && pluginName + ': editor-set-element-after callback not TRUE'});
    }
}

class PluginAPI {
    static Article = Article;
    static Editor = Editor;

    static async request(name, data, callback) {
        return sendMessage(createMessage(name, data), callback);
    }

    static async getJWT() {
        const jwt = await sendMessage(createMessage('get-jwt'), data => {return data?.appName !== pluginName && pluginName + ': Invalid jwt received'});
        if (jwt?.debug) {
            Debug.setDebug(Object.fromEntries([['plugin:' + pluginName, jwt.debug]]));
        }
        return jwt;
    }

    static async openTagCreationDialog(tag) {}

    static async reloadIframe() {}

    static async getPluginName() {}

    static setPluginName(name) {
        if (typeof name === 'string' && name.match(/^[a-z]+$/)) {
            pluginName = name;
        }
    }

    static async showInfoMsg(message, options) {
        return sendMessage(createMessage('show-message-info', {...options, message}), data => {return data !== true && pluginName + ': show-message-info callback not TRUE'});
    }

    static async showWarningMsg(message, options) {
        return sendMessage(createMessage('show-message-warning', {...options, message}), data => {return data !== true && pluginName + ': show-message-warning callback not TRUE'});
    }

    static async showErrorMsg(message, options) {
        return sendMessage(createMessage('show-message-error', {...options, message}), data => {return data !== true && pluginName + ': show-message-error callback not TRUE'});
    }

    static async showLoader(message) {}

    static async hideLoader() {}

    static async createTag(tag) {}

    static async generateArticleUrl(id) {
        return sendMessage(createMessage('generate-article-url', {id}), data => {return !(typeof data === 'string' && data.length > 0) && pluginName + ': generate-article-url callback not string'});
    }

    static async getCurrentUser() {}

    static async getDrPublishConfiguration() {}

    static async setConfiguration(config, options) {}

    static async emit(name, data) {}

    static async increaseRequiredActionCounter() {}

    static async decreaseRequiredActionCounter() {}

    static async clearRequiredActionCounter() {}

    static async setRequiredActionCounter(count) {}

    static async getEmbeddedObjectTypes() {}

    static async hide() {}

    static async createModal(content, options) {}

    static async updateModal(content) {}

    static async closeModal(destroy) {}

    static async getModalInputs() {}

    /**
     * Get the plugin configuration
     * @throws Rejects promise if response does not contain {appName: pluginName}
     * @returns {Array} Plugin configuration
     */
    static async getConfiguration() {
        const config = await sendMessage(createMessage('get-configuration'), data => {return data?.appName !== pluginName && pluginName + ': Invalid config object received'});
        if (config?.debug) {
            Debug.setDebug(Object.fromEntries([['plugin:' + pluginName, config.debug]]));
        }
        return config;
    }
    
   /**
     * Create an embedded object
     * @throws Rejects promise if response not a string containing numbers
     * @returns {string} ID of the embedded object
     */
    static async createEmbeddedObject() {
        return sendMessage({...createMessage('create-embedded-object', {typeId: 6}, true), callback: null}, data => {return !data?.match(/^\d+$/) && pluginName + ': create-embedded-object callback not numeric ID'});
    }

    /**
     * Insert plugin element with specified into and append provided element as a child
     * @throws Rejects promise if response not in the form "asset-[\d]+"
     * @param {string} id
     * @param {string} element HTML string
     * @returns {string} The internalId of the inserted plugin element
     */
    static async editorInsertElement(id, element) {
        return sendMessage(
            createMessage('editor-insert-element', {select: true, element: `<div id="asset-${id}" data-internal-id="${id}" class="dp-script-asset">${element}</div>`}), 
            data => {return !data?.match(/^asset-[\d]+$/) && pluginName + ': editor-insert-element callback not in the form: "asset-[\d]+"'}
        );
    }

    /**
     * @throws Rejects promise if response not TRUE
     * @param {string} internalId 
     * @param {Object} options
     * @returns {boolean} Success status
     */
    static async addEmbeddedAsset(internalId, options) {
        return sendMessage(
            {...createMessage('add-embedded-asset', {embeddedTypeId: 6, assetType: 'script', internalId, options}, true), callback: null},
            data => {return data !== true && pluginName + ': add-embedded-asset callback not TRUE'}
        );
    }

    /**
     * @throws Rejects promise if response doesn't contain {dpDigitalAssetId: ^\d+$}
     * @param {string} id
     * @returns {Object}
     */
    static async getAssetData(id) {
        return sendMessage(createMessage('get-asset-data', {data: id}), data => {return !data?.dpDigitalAssetId?.match(/^\d+$/) && pluginName + ': get-asset-data callback doesn\'t contain {dpDigitalAssetId:^\d+$}'});
    }

    /**
     * Activate specified built-in plugin element menus
     * @throws Rejects promise if response not TRUE
     * @param {...string} menus An list of menu name strings
     * @returns {boolean} Success status
     */
    static async editorInitializeMenu(...menus) {
        return sendMessage(createMessage('editor-initialize-menu', {menus}), data => {return data !== true && pluginName + ': editor-initialize-menu callback not TRUE'});
    }

    static extendApi(group, name, method) {
        const eventKey = `extend-api_${group}-${name}`;

        addListener(eventKey, async data => {
            const response = await method(data.data);
            const message = createMessage(data.eventKey, {data: response});
            delete message.callback
            delete message.eventKey;

            sendMessage(message, data => {return data !== true && pluginName + ': register-menu-action-response callback not TRUE'});
        });

        const message = createMessage('extend-api', {group, name, action: {type: 'function', eventKey}});
        delete message.callback;

        // @todo: This has no callback, it probably should
        sendMessage(message);
        return true;
    }

    static async callExtendedApi(group, name, data) {
        const message = createMessage('call-extended-api', {group, name, data}, true);
        delete message.callback;

        return sendMessage(message, data => {return data === undefined && pluginName + ': call-extended-api callback is undefined'});
    }

    static async awaitExtendedApi(group, name) {
        const message = createMessage('await-extended-api', {group, name}, true);
        delete message.callback;

        return sendMessage(message, data => {return data !== true && pluginName + ': await-extended-api callback not TRUE'});
    }

    /**
     * Bring a plugin or the Mic (empty string as pluginName) into focus
     * @throws Rejects promise if response not {success: TRUE}
     * @param {string} pluginName
     * @returns {boolean} Success status
     */
    static async giveFocus(pluginName) {
        return sendMessage({...createMessage('give-focus', {pluginName, start: false}, true), callback: null}, data => {return data?.success !== true && pluginName + ': give-focus callback not TRUE'});        
    }

    static async searchDrLib(query) {
        const message = createMessage('drlib-search', {query}, true);
        message.data.success = message.data.callback;
        delete message.data.callback;
        delete message.callback;
        return sendMessage(message, data => {return !(typeof data === 'object' && data && data instanceof Object) && pluginName + ': drlib-search callback not object'}, message.data.success.eventKey);
    }

    static async on(name, callback) {
        addListener(name, callback);
        return sendMessage(createMessage('on-api-event', {name}), data => {return data !== true && pluginName + ': on-api-event callback not TRUE'});
    }

    /* Compatibility with the plugins using the prototype versions */
    static getId = Article.getId;
    static editorPaneMaximize = Article.maximizePluginWindow;
    static restoreAppWindow = Article.restorePluginWindow;
    static registerMenuAction = Editor.registerMenuAction;
    static getEditorType = Editor.getEditorType;
    static getByline = Article.getByline;
    static setByline = Article.setByline;
    static editorElementSetByid = Editor.setElementContentById;
    static updateEmbeddedAsset = Editor.updateEmbeddedAsset;
    static getPublication = Article.getPublication;
}

let pluginName;

const addListener = (eventName, callback, isMessage = false) => {
    const handler = event => {
        isMessage && window.removeEventListener(eventName, handler);
        callback(event.detail !== undefined ? event.detail : event);
    }

    window.addEventListener(eventName, handler);
};

const createMessage = (appSpec, data = {}, callbackInData = false) => {
    const message = {type: appSpec, callback: `${appSpec}_${Math.floor(Math.random() * 100000)}`, data: {src_app: pluginName, ...data}};
    if (callbackInData) {
        message.data.callback = {type: 'function', eventKey: message.callback};
    }
    return message;
};

const sendMessage = async (message, responseErrorChecker, eventName = null) => {
    eventName ??= message.callback;
    eventName ??= message.data?.callback?.eventKey;

    Debug.debug('plugin:' + pluginName, 'sendMessage', eventName ?? message.type, message);

    return new Promise((resolve, reject) => {
        if (!(typeof pluginName === 'string' && pluginName.match(/^[a-z]+$/))) {
            reject('PluginAPI: Missing or invalid pluginName');
        }

        addListener(eventName, data => {
            Debug.debug('plugin:' + pluginName, 'onMessage', eventName, data);

            let error;
            if (responseErrorChecker && (error = responseErrorChecker(data))) {
                return reject(error);
            }
            return resolve(data);
        }, true);

        window.parent.postMessage(JSON.stringify(message), '*');
    });
};

const receiveMessage = data => {
    data = JSON.parse(data.data);

    const type = data.type === 'event' ? data.data?.type : data.type;
    if (!type) {
        return;
    }

    const truncate = (data, extendApi = false) => {
        // extendApi calls have nested garbage keys
        return data?.data !== undefined && !(extendApi && data.type === undefined && data.data?.eventKey === undefined) ? truncate(data.data, extendApi) : data;
    }
    data = truncate(data, !!type?.match(/^extend-api_/));

    window.dispatchEvent(new CustomEvent(type, {detail: data}));
};
addListener('message', receiveMessage);

export {PluginAPI};