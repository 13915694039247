import React, {
  useReducer,
  useEffect
} from 'react'

import {
  Dropdown,
  Menu,
  Header
} from 'semantic-ui-react'

import axios from 'axios'

import {
  PluginAPI
} from '../../lib/aptoma/PluginAPI-5.0'

import ProjectSettings from './ProjectSettings'
import './App.scss'
import 'fomantic-ui/dist/semantic.min.css'

PluginAPI.setPluginName( 'bit' )

/**
 * Article context retrieved from API synchroneously,
 * blocks execution until timeout.
 */

const getArticleId = ( ctx ) => {
  return PluginAPI.Article.getId()
    .then( ( articleId ) => {
      return new Promise( ( resolve ) => {
        ctx.articleId = articleId
        resolve( ctx )
      } ) } )
}

const getArticleContent = ( ctx ) => {
  return PluginAPI.Article.getCurrentContent()
    .then( ( articleContent ) => {
      return new Promise( ( resolve ) => {
        ctx.headline = articleContent.headline ?? ''
        resolve( ctx )
      } ) } )
}

const getArticleCategories = ( ctx ) => {
  return PluginAPI.Article.getSelectedCategories()
    .then( ( articleCategories ) => {
      return new Promise( ( resolve ) => {
        ctx.categories = articleCategories
        resolve( ctx )
      } ) } )
}

const timeout = ( limit ) => {
  return new Promise( ( _, reject ) => {
    timeout.clear = ( ) => {
      clearTimeout( timeout.handle )
    }
    timeout.handle = setTimeout( ( ) => {
      reject( new Error( 'No article context' ) )
    }, limit )
  } )
}

const ctxCompleted = ( ctx ) => {
  timeout.clear()
  return ctx
}

const getArticleContext = async ( ) => {
  const ctx = { }
  return Promise.race( [
    timeout( 3000 ),
    getArticleId( ctx )
      .then( getArticleContent )
      .then( getArticleCategories )
  ] )
  .then( ctxCompleted )
}

const articleContext =
  await getArticleContext().catch( ( ) => { /* Ignore timeout error */ } )

const App = () => {

  const reducer = ( state, action ) => {
    switch( action.type ) {
      case 'populate_project_list':
        return {
          ...state,
          baseUrl: action.baseUrl,
          projectList: action.projectList
        }
      case 'set_current_project':
        return {
          ...state,
          articleContext: state.articleContext,
          currentProject: action.currentProject
        }
      default:
        return state
    }
  }

  const [ state, dispatch ] = useReducer( reducer, {
    projectList: [ ],
    baseUrl: null,
    currentProject: null,
    articleContext
  } )

  const getProjectSettings = ( projectKey ) => {
    const projectSettings = state.projectList.filter( ( p ) => {
      return p.key === projectKey
    } )[ 0 ] ?? null
    return projectSettings
  }

  const fetchProjectList = ( pluginConfig ) => {
    return new Promise( ( resolve, reject ) => {
      axios.get( `${pluginConfig.url}/projects` )
        .then( ( response ) => {
          const projectList =
            response.data.projectList.reduce( ( accumulator, project ) => {
              return [ ...accumulator, ...( project.subprojects || project ) ]
            }, [ ] )
          resolve( {
            type: 'populate_project_list',
            projectList,
            baseUrl: response.data.baseUrl
          } )
        } )
        .catch( ( reason ) => {
          reject( reason )
        } )
    } )
  }

  const getPluginConfiguration = () => {
    return new Promise( ( resolve, reject ) => {
      PluginAPI.getConfiguration()
        .then( ( pluginConfiguration ) => {
          App.pluginConfiguration = pluginConfiguration
          resolve( App.pluginConfiguration )
        } )
        .catch( ( reason ) => {
          reject( reason )
        } )
    } )
  }

  const initialize = () => {
    getPluginConfiguration()
      .then( fetchProjectList )
      .then( dispatch )
  }

  useEffect( () => {
    initialize()
  }, [ ] )

  const setCurrentProject = ( itemKey ) => {
    return new Promise( ( resolve ) => {
      const currentProject = getProjectSettings( itemKey )
      resolve( currentProject )
    } )
  }

  const fetchProjectConfiguration = ( currentProject ) => {
    return new Promise( ( resolve ) => {
      axios.post( `${state.baseUrl}${currentProject.configPath}`, { articleContext, currentProject } )
        .then( ( response ) => {
          resolve( {
            type: 'set_current_project',
            currentProject: {
              ...App.pluginConfiguration,
              ...currentProject,
              ...response.data
            }
          } )
        } )
    } )
  }

  const onClickProjectItem = ( itemKey ) => {
    setCurrentProject( itemKey )
      .then( fetchProjectConfiguration )
      .then( dispatch )
  }

  const onSaveProjectSettings = ( newSettings ) => {
    return new Promise( ( resolve ) => {
      axios.put( newSettings.configPath, { currentProject: newSettings, articleContext } )
        .then( ( response ) => {
          dispatch( {
            type: 'set_current_project',
            currentProject: {
              ...App.pluginConfiguration,
              ...response.data
            }
          } )
        } )
        .then( resolve )
    } )
  }

  const onCloseProjectSettings = () => {
    dispatch( {
      type: 'set_current_project',
      currentProject: null
    } )
  }

  const onDeleteProjectSettings = ( ) => {
    return new Promise( ( resolve ) => {
      if( articleContext.articleId > 0 ) {
        axios.delete( `${state.currentProject.configPath}?articleId=${articleContext.articleId}` )
          .then( ( ) => {
            onCloseProjectSettings()
          } )
          .then( resolve )
      }
      else {
        resolve()
      }
    } )
  }

  const createEmbeddedObject = () => {
    return new Promise( ( resolve ) => {
      PluginAPI.createEmbeddedObject()
        .then( ( id ) => {
          resolve( {
            id,
            html: `<p><strong>${state.currentProject.label}:</strong> ${state.currentProject.description}</p>`
          } )
        } )
    } )
  }

  const insertEditorPlaceholder = ( params ) => {
    return new Promise( ( resolve ) => {
      PluginAPI.editorInsertElement( params.id, params.html )
        .then( ( ) => {
          resolve( params )
        } )
    } )
   }

  const addEmbeddedAsset = ( params ) => {
    const assetData = {
      type: 'bit',
      description: state.currentProject.description,
      id: crypto.randomUUID(),
      href: `${state.baseUrl}/quiz/politikertyper/main.js`
    }
    return PluginAPI.addEmbeddedAsset( params.id, assetData )
  }

  const injectCurrentProject = () => {
    if( state.currentProject ) {
      createEmbeddedObject()
        .then( insertEditorPlaceholder )
        .then( addEmbeddedAsset )
    }
  }

  const jsxProjectMenuItems = state.projectList.map( ( project ) => {
    return (
      <Menu.Item
        key={project.key}
        value={project.key}
        onClick={ () => onClickProjectItem( project.key ) }
      >
        <Header>
          {project.label}
        </Header>
        <p>
          {project.description}
        </p>
      </Menu.Item>
    )
  } )

  const projectSettings = state.currentProject
                        ? <ProjectSettings
                            currentProject={state.currentProject}
                            baseUrl={state.baseUrl}
                            onInject={injectCurrentProject}
                            onSave={onSaveProjectSettings}
                            onClose={onCloseProjectSettings}
                            onDelete={onDeleteProjectSettings}
                          />
                        : <></>

  return (
    <div id="bit" className="ui basic padded segment">
      <h1 className="ui text">Special content</h1>
      <div className="controls">
        <p className="ui text subtitle">
          Configuration and embedding
        </p>
        <div>
          <Dropdown
            fluid
            text={state.currentProject?.label ?? '[ Load project ]'}
            className="projects"
          >
            <Dropdown.Menu>
              {jsxProjectMenuItems}
            </Dropdown.Menu>
          </Dropdown>
          {projectSettings}
        </div>
      </div>
    </div>
  )
}

export default App
