import React from 'react'
import { createRoot } from 'react-dom/client'
import axios from 'axios'

import App from './components/App'

import './main.scss'

const searchParams = new URLSearchParams( window.location.href )
axios.defaults.headers.common.Authorize = searchParams.get( 'jwt' ) ?? ''

const root = createRoot( document.getElementById( 'app-root' ) )
root.render( <App /> )
