/*
    Use Debug.setDebug() to set what should be sent to window.console or not.
        This method can be ran multiple times and either have single or multiple objects with {context, level} pairs.
        Context will generally the name of your application or context, like "DrLib" or "collab".
        Sending a string value in #levels like "error" will assign it to context = "global".

        Examples:
            Debug.setDebug('warn');
            Debug.setDebug({ntb: 'error'});
            Debug.setDebug('warn', {ntb: 'info'});
            Debug.setDebug('warn', {ntb: 'info'}, {DrLib: 'error'});
            Debug.setDebug('warn', {ntb: 'info', DrLib: 'error'});
            Debug.setDebug({DrLib: 'log'}, 'warn') && Debug.setDebug({ntb: 'info'});
    
    Use either of these insead of the window.console variants:
        Debug.error()
        Debug.warn()
        Debug.log()
        Debug.info()
        Debug.debug()
    
        If multiple parameters are added, the 1st will be treated as context
            Debug.error("DrLib", "Broken");
*/
class Debug {
    static #colors = ['blue', 'fuchsia', 'gray', 'green', 'maroon', 'navy', 'olive', 'purple', 'red', 'silver', 'teal', 'yellow']

    static #levels = {
        'error': 1,
        'warn': 2,
        'log': 3,
        'info': 4,
        'debug': 5
    }

    static #defaultContext = 'global';
    static #contextColors = {}
    static #debug = {global: this.#levels.error}

    static setDefaultContext(context) {
        if (typeof context === 'string' && context.length) {
            this.#defaultContext = context;
        }
    }

    static setDebug(...contexts) {
        // Transform string parameters like "warning" into object form like {global: "warning"}
        contexts = contexts.map(context => typeof context === 'string' && this.#levels.hasOwnProperty(context) ? {[this.#defaultContext]: context} : context);

        /*
            For each parameter extract each {context, level} pair, transform into integer form and merge into single object
            Meaning [{global: "warn"}, {ntb: "info", DrLib: "debug"}] is transformed into {global: 2, ntb: 4, DrLib: 5}
        */
        const debug = contexts.reduce((debug, context) => Object.assign(debug, Object.entries(context).reduce((carry, item) => {
            if (!(typeof item === 'object' && item)) {
                return carry;
            }

            if (typeof item[1] === 'string' && this.#levels.hasOwnProperty(item[1])) {
                carry[item[0]] = this.#levels[item[1]];
            }

            return carry;
        }, {})), {});
        // Merge with current debug object, overwriting new duplicate keys
        this.#debug = Object.assign(this.#debug, debug);
    }

    static #private(level, context, ...parameters) {
        if (!parameters.length || (typeof context !== 'string' || !context.length)) {
            parameters.splice(0, 0, context);
            context = this.#defaultContext;
        }

        let label = context;
        if (!this.#debug.hasOwnProperty(context)) {
            context = 'global';
        }

        const levelInt = this.#levels[level];
        if (levelInt <= this.#debug[context]) {
            if (label !== 'global') {
                /*
                    Pick a "random" color based on checksum of label.
                    Since this is deterministic, the same color will be used on "both sides"/frames of the PluginAPI for
                        the same context even though they're using different instances of this module.
                */
                if (!this.#contextColors.hasOwnProperty(label)) {
                    this.#contextColors[label] = this.#colors[checksum(label) % this.#colors.length];
                }
                parameters.splice(0, 0, '%c' + label, `color: ${this.#contextColors[label]}`);
            }

            console[level](...parameters);
        }
    }

    static error = this.#private.bind(this, 'error');
    static warn = this.#private.bind(this, 'warn');
    static log = this.#private.bind(this, 'log');
    static info = this.#private.bind(this, 'info');
    static debug = this.#private.bind(this, 'debug');
}

// Turn a string into a deterministic number, this is used to ensure a context always get the same "random" color
function checksum(string) {
    const length = string.length;
    let checksum = 0x12345678;

    for (let i = 0; i < length; i++) {
        checksum += (string.charCodeAt(i) * (i + 1));
    }

    return checksum & 0xffffffff;
}

export default Debug;