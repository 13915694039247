import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Segment,
  Form,
  List,
  Button,
  Input,
  Divider,
  Header,
  Icon,
  Checkbox
} from 'semantic-ui-react'
import './ProjectSettings.scss'
import 'fomantic-ui/dist/semantic.min.css'
import Dialog from './Dialog'

const ProjectSettings = ( props ) => {

  const [ state, setState ] = useState( {
    dirty: false,
    dialog: null,
    error: null,
    ok: null,
    baseUrl: props.baseUrl,
    currentProject: props.currentProject ?? { }
  } )

  const setDirty = ( dirty = true ) => {
    setState( ( prevState ) => {
      return { ...prevState, dirty }
    } )
  }

  const setClean = () => {
    setDirty( false )
  }

  const showDialog = ( dlgOpts ) => {
    setState( ( prevState ) => {
      return {
        ...prevState,
        dialog: dlgOpts
      }
    } )
  }

  const closeDialog = () => {
    showDialog( null )
  }

  const onInject = ( ) => {
    if( props.onInject ) {
      props.onInject()
    }
  }

  const onToggleCustom = ( _, e ) => {
    setState( ( prevState ) => {
      const newState = { ...prevState }
      newState.dirty = true
      newState.currentProject.customParameters[ e.index ].value =
        !( newState.currentProject.customParameters[ e.index ].value ?? false )
      return newState
    } )
  }

  const onUpdate = ( e ) => {
    const fieldIndex = e.target.dataset.index || e.target.parentNode.dataset.index
    const fieldValue = e.target.value || e.target.parentNode.dataset.value
    setState( ( prevState ) => {
      const newState = { ...prevState }
      newState.dirty = true
      if( /^\d+$/.test( fieldIndex ) ) {
        // Numeric index = custom parameter
        newState.currentProject.customParameters[ fieldIndex ].value = fieldValue
      }
      else {
        // String identifier = common parameter
        newState.currentProject[ fieldIndex ] = fieldValue
      }
      return newState
    } )
  }

  const onSave = () => {
    props.onSave( state.currentProject )
      .then( setClean )
      .then( ( ) => {
        setState( ( prevState ) => {
          return {
            ...prevState,
            error: null,
            ok: 'Project saved'
          }
        } )
      } )
      .catch( ( error ) => {
        setState( ( prevState ) => {
          return {
            ...prevState,
            error: error.message ?? 'An error occured',
            ok: null
          }
        } )
      } )
  }

  const discardChanges = () => {
    setClean()
    props.onClose()
  }

  const onClose = () => {
    if( state.dirty ) {
      const dlgOpts = {
        header: 'Warning!',
        message: 'Project settings have been modified. Discard changes?',
        actions: [
          {
            key: 'abort',
            content: 'Abort',
            onClick: closeDialog
          },
          {
            key: 'discard',
            content: 'Discard changes',
            negative: true,
            onClick: discardChanges
          }
        ]
      }
      showDialog( dlgOpts )
    }
    else {
      discardChanges()
    }
  }

  const onDelete = () => {
    props.onDelete( state.currentProject )
  }

  const onAddToList = ( e ) => {
    if( e.value ) {
      setState( ( prevState ) => {
        const fieldIndex = parseInt( e.parentNode.dataset.index, 10 )
        const newState = { ...prevState }
        newState.dirty = true
        if( !( newState.currentProject.customParameters[ fieldIndex ].items ) ) {
          newState.currentProject.customParameters[ fieldIndex ].items = [ ]
        }
        newState.currentProject.customParameters[ fieldIndex ].items.push( e.value )
        e.value = ''
        return newState
      } )
    }
  }

  const onRemoveFromList = ( e ) => {
    const fieldIndex = parseInt( e.target.dataset.fieldIndex, 10 )
    const itemIndex = parseInt( e.target.dataset.itemIndex, 10 )
    if( !Number.isNaN( fieldIndex ) && !Number.isNaN( itemIndex ) ) {
      setState( ( prevState ) => {
        const newState = { ...prevState }
        newState.dirty = true
        const { items } = newState.currentProject.customParameters[ fieldIndex ]
        newState.currentProject.customParameters[ fieldIndex ].items = (
          items.slice( 0, itemIndex ).concat( items.slice( itemIndex + 1 ) )
        )
        return newState
      } )
    }
  }

  const status = state.error
               ? <Segment inverted color="red">{state.error}</Segment>
               : state.ok ? <Segment inverted color="green">{state.ok}</Segment>
                          : <Segment inverted disabled>Ready</Segment>

  const customParameters = state.currentProject.customParameters ?? [ ]
  const jsxCustomParameters = customParameters.map( ( custParam, fieldIndex ) => {
    switch( custParam.type ) {
      case 'text':
        return (
          <Form.Field key={custParam.key}>
            <label>{custParam.label}</label>
            <Input
              data-index={fieldIndex}
              onChange={onUpdate}
              defaultValue={custParam.value}
            />
          </Form.Field>
        )
      case 'bool':
        return (
          <Form.Field key={custParam.key}>
            <Checkbox
              toggle
              className={custParam.key}
              index={fieldIndex}
              onClick={onToggleCustom}
              label={custParam.label}
              checked={custParam.value} />
          </Form.Field>
        )
      case 'list':
        {
          const listItems = ( custParam.items ?? [ ] ).length > 0
                          ? custParam.items.map( ( item, itemIndex ) => {
                              return (
                                <List.Item key={`custprop_field${fieldIndex}_item${itemIndex}`}>
                                  <List.Content floated='right'>
                                    <Icon
                                      data-field-index={fieldIndex}
                                      data-item-index={itemIndex}
                                      name="trash alternate outline"
                                      title="Delete list item"
                                      onClick={onRemoveFromList}
                                    />
                                  </List.Content>
                                  <List.Icon name='check' size='small' verticalAlign='middle' />
                                  <List.Content>
                                    <List.Description>{item}</List.Description>
                                  </List.Content>
                                </List.Item>
                              )
                            } )
                          : <List.Item>
                              <List.Content>
                                <i>[ List is empty ]</i>
                              </List.Content>
                            </List.Item>
          return (
            <div className="field" key={`custprop_field${fieldIndex}`}>
              <Header size="small">{custParam.label}</Header>
              <List divided relaxed key={custParam.key}>
                {listItems}
              </List>
              <Input
                data-index={fieldIndex}
                defaultValue=""
                placeholder="Legg til i listen"
                onKeyPress={
                  ( e ) => {
                    if( e.code === 'Enter' ) {
                      onAddToList( e.target )
                      e.preventDefault()
                    }
                  }
                }
                fluid
              />
              <Divider clearing />
            </div>
          )
        }
      default:
        return <></>
    }
  } )

  return (
    <>
      <Dialog dlgOpts={state.dialog} />
      {status}
      <Form className="project-settings">
        <Header as="h4" floated="left" className="plugin-settings">
          <Icon name="cogs" />Plugin configuration
        </Header>
        <Divider clearing />
        <Form.Field>
          <label>Base URL (readonly)</label>
          <input
            disabled={true}
            data-index="baseUrl"
            defaultValue={state.baseUrl ?? ''} />
        </Form.Field>
        <Form.Field>
          <label>Configuration path (relative to base URL)</label>
          <input
            onChange={onUpdate}
            data-index="configPath"
            value={state.currentProject.configPath ?? ''} />
        </Form.Field>
        <Header as="h4" floated="left" className="app-settings">
          <Icon name="cogs" />App settings
        </Header>
        <Divider clearing />
        {jsxCustomParameters}
        <Form.Field>
          <Button floated="right" onClick={onSave} disabled={!state.dirty}>Save</Button>
          <Button floated="right" onClick={onClose}>Close</Button>
          <Button floated="right" onClick={onDelete}>Delete</Button>
          <Button floated="left" icon labelPosition="left" onClick={onInject}>
            <Icon name="left arrow" />
            Inject into article
          </Button>
        </Form.Field>
      </Form>
    </>
  )
}

ProjectSettings.propTypes = {
  currentProject: PropTypes.object,
  baseUrl: PropTypes.string,
  onInject: PropTypes.func,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  onDelete: PropTypes.func
}

export default ProjectSettings
