// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js!../../../scss_common/colors.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#bit .project-settings {
  padding: 15px 0; }
  #bit .project-settings * {
    color: var(--default-fg-color); }
  #bit .project-settings .fullpage .checked label {
    color: var(--bg-color-handle-primary-highlighted); }
  #bit .project-settings .app-settings, #bit .project-settings .plugin-settings {
    padding-top: 30px; }
  #bit .project-settings .field input[disabled] {
    opacity: 1.0 !important;
    background-color: transparent !important;
    border-style: none; }
  #bit .project-settings .checkbox.activate {
    padding-top: 5px; }
  #bit .project-settings .ui.divided.list > .item {
    border-bottom: 1px solid var(--divider-color) !important;
    border-bottom-style: dashed !important;
    padding-bottom: 0 !important;
    padding-top: 13px !important;
    min-height: 35px; }
  #bit .project-settings .ui.list .item i.icon {
    cursor: pointer;
    margin-bottom: 2px; }
    #bit .project-settings .ui.list .item i.icon:hover {
      color: var(--bg-color-handle-primary-highlighted) !important; }
  #bit .project-settings .field {
    padding: 5px 0; }
    #bit .project-settings .field > .header {
      margin-bottom: 0 !important; }
`, "",{"version":3,"sources":["webpack://./src/components/App/ProjectSettings/ProjectSettings.scss"],"names":[],"mappings":"AAEA;EAKI,eAAe,EAAA;EALnB;IAGM,8BAAgC,EAAA;EAHtC;IAOM,iDAAmD,EAAA;EAPzD;IAUM,iBAAiB,EAAA;EAVvB;IAaM,uBAAuB;IACvB,wCAAwC;IACxC,kBAAkB,EAAA;EAfxB;IAkBM,gBAAgB,EAAA;EAlBtB;IAqBM,wDAA0D;IAC1D,sCAAsC;IACtC,4BAA4B;IAC5B,4BAA4B;IAC5B,gBAAgB,EAAA;EAzBtB;IA4BM,eAAe;IAIf,kBAAkB,EAAA;IAhCxB;MA8BQ,4DAA8D,EAAA;EA9BtE;IAmCM,cAAc,EAAA;IAnCpB;MAqCQ,2BAA2B,EAAA","sourcesContent":["@import url( '../../../scss_common/colors.scss' );\n\n#bit {\n  .project-settings {\n    * {\n      color: var( --default-fg-color );\n    }\n    padding: 15px 0;\n    .fullpage .checked label {\n      color: var( --bg-color-handle-primary-highlighted );\n    }\n    .app-settings, .plugin-settings {\n      padding-top: 30px;\n    }\n    .field input[disabled] {\n      opacity: 1.0 !important;\n      background-color: transparent !important;\n      border-style: none;\n    }\n    .checkbox.activate {\n      padding-top: 5px;\n    }\n    .ui.divided.list>.item {\n      border-bottom: 1px solid var( --divider-color ) !important;\n      border-bottom-style: dashed !important;\n      padding-bottom: 0 !important;\n      padding-top: 13px !important;\n      min-height: 35px;\n    }\n    .ui.list .item i.icon {\n      cursor: pointer;\n      &:hover {\n        color: var( --bg-color-handle-primary-highlighted ) !important;\n      }\n      margin-bottom: 2px;\n    }\n    .field {\n      padding: 5px 0;\n      & > .header {\n        margin-bottom: 0 !important;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
