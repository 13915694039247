// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --divider-color: rgba(255,255,255,.1);
  --default-fg-color: #ccc;
}
`, "",{"version":3,"sources":["webpack://./src/scss_common/colors.scss"],"names":[],"mappings":"AAAA;EACE,qCAAqC;EACrC,wBAAwB;AAC1B","sourcesContent":[":root {\n  --divider-color: rgba(255,255,255,.1);\n  --default-fg-color: #ccc;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
